import { Injectable } from '@angular/core';
import {
  NbGlobalPhysicalPosition,
  NbToastrService,
  NbComponentStatus,
} from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})

export class ToasterService {

  constructor(
    private toastrService: NbToastrService,
  ) { }

  showToaster(type: NbComponentStatus, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 2000,
      hasIcon: false,
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      preventDuplicates: false,
    };

    this.toastrService.show('', body, config);
  }
}
