import { Component, Input } from '@angular/core';
import { User } from '../../../pages/user/user';

@Component({
  selector: 'ngx-avatar',
  templateUrl: './avatar.component.html',
})

export class AvatarComponent {

  @Input() rowData: User;

}
