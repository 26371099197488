import { Injectable } from '@angular/core';
import { User } from '../pages/user/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationEndPoints } from '../end-points/authentication';

@Injectable({
  providedIn: 'root',
})

export class ThemeService {

  baseEndpoint = environment.baseUrl;
  authenticationEndPoints = AuthenticationEndPoints;

  constructor(
    private http: HttpClient,
  ) { }

  getAuthUser() {
    return this.http.get<User>(this.baseEndpoint.concat(this.authenticationEndPoints.authUser));
  }
}
