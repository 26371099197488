import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  token = localStorage.getItem('auth_app_token');

  constructor() { }

  isTokenAvailable() {
    if (this.token) {
      return true;
    } else {
      return false;
    }
  }
}
