import { Organization } from '../organization/organization';

export class User {
  id?: number;
  name?: string;
  gender?: string;
  avatar?: string;
  email?: string;
  organization?: Organization;
}
