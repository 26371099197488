import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})

export class AuthguardService implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate() {
    const authenticated = this.authService.isTokenAvailable();

    if (authenticated) {
      return true;
    } else {
      this.router.navigate(['auth/signin']);
      return false;
    }
  }

  canActivateChild() {
    const authenticated = this.authService.isTokenAvailable();

    if (authenticated) {
      return true;
    } else {
      this.router.navigate(['auth/signin']);
      return false;
    }
  }
}
