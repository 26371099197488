import { Component } from '@angular/core';

@Component({
  selector: 'ngx-outer-layout',
  styleUrls: ['./outer-layout.component.scss'],
  template: `
    <nb-layout windowMode>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})

export class OuterLayoutComponent { }
